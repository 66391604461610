import React, { useState, useEffect } from "react";
import { EuiTitle, EuiDatePickerRange, EuiDatePicker } from "@elastic/eui";
import PropTypes from "prop-types";

const DateRangePicker = ({ onChange, label, resetTrigger }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const isInvalid = startDate && endDate && startDate > endDate;

  useEffect(() => {
    if (startDate && endDate) {
      const startOfDay = new Date(startDate);
      startOfDay.setUTCHours(0, 0, 0, 0);
      const startUTC = startOfDay.toISOString();

      const endOfDay = new Date(endDate);
      endOfDay.setUTCHours(23, 59, 59, 999);
      const endUTC = endOfDay.toISOString();

      onChange({
        dateMin: startUTC,
        dateMax: endUTC,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (resetTrigger) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [resetTrigger]);

  return (
    <div data-testid="overridable-date-range-facet">
      <EuiTitle size="xxs">
        <h3>{label}</h3>
      </EuiTitle>
      <EuiDatePickerRange
        startDateControl={
          <EuiDatePicker
            selected={startDate}
            onChange={setStartDate}
            startDate={startDate}
            endDate={endDate}
            isInvalid={isInvalid}
            placeholder="From"
            aria-label="Start date"
          />
        }
        endDateControl={
          <EuiDatePicker
            selected={endDate}
            onChange={setEndDate}
            startDate={startDate}
            endDate={endDate}
            isInvalid={isInvalid}
            placeholder="To"
            aria-label="End date"
          />
        }
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  resetTrigger: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default DateRangePicker;
